<template>
  <v-list-item-avatar>
    <v-img contain v-if="type == 0" src="@/assets/ArtifactTypes/nugetIcon.png"></v-img>
    <v-img contain v-if="type == 1" src="@/assets/ArtifactTypes/npm.png"></v-img>
    <v-img contain v-if="type == 2" src="@/assets/ArtifactTypes/maven.png"></v-img>
    <v-img contain v-if="type == 3" src="@/assets/ArtifactTypes/docker.png"></v-img>
    <v-img contain v-if="type == 4" src="@/assets/ArtifactTypes/pypi.png"></v-img>
    <v-img contain v-if="type == 5" src="@/assets/ArtifactTypes/bower.png"></v-img>
    <v-img contain v-if="type == 6" src="@/assets/ArtifactTypes/helm.png"></v-img>
    <v-img contain v-if="type == 7" src="@/assets/ArtifactTypes/raw.png"></v-img>
  </v-list-item-avatar>
</template>

<script>
export default {
  props: ['type']
}
</script>
