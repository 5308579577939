<template>
  <v-dialog v-model="openModal" width="1200">
    <v-card v-if="selectedPackage">
      <v-list-item>
        <package-avatar :type="repositoryTypes[selectedPackage.type].value" />
        <v-list-item-content>
          <v-list-item-title class="headline">{{ selectedPackage.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-card-text>
        <v-container v-if="selectedPackage">
          <v-row>
            <v-col>
              <h2>Package Details:</h2>
              <br/>
              <div><span style="font-weight: bold;">Package Name:</span> {{ selectedPackage.name }}</div>
              <div><span style="font-weight: bold;">Description:</span></div>
              <div v-html="compiledMarkdown(selectedPackage.description)"></div>
              <div><span style="font-weight: bold;">Published By:</span> {{ selectedPackage.teamName }}</div>
              <div><span style="font-weight: bold;">Package Type:</span> {{ repositoryTypes[selectedPackage.type].key }}</div>
              <div><span style="font-weight: bold;">Tags:</span></div>
              <div v-if="selectedPackage.tags">
                <v-chip
                  v-for="(tag, tagIndex) in selectedPackage.tags"
                  :key="tagIndex"
                  class="ma-1 pa-3"
                >
                  {{tag}}
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Connection Details:</h2>
              <br/>
              <div><span style="font-weight: bold;">Repository Name:</span> {{ publicRepo.displayName }}</div>
              <div><span style="font-weight: bold;">Repository Url:</span> {{ publicRepo.url }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import packageAvatar from './packageAvatar.vue'
import marked from 'marked'

export default {
  name: 'package-dialog',
  props: {
    selectedPackage: {
      required: true
    },
    open: {
      required: true
    },
    publicRepos: {
      required: true
    }
  },
  components: {
    'package-avatar': packageAvatar
  },
  computed: {
    publicRepo: function () {
      if (this.selectedPackage) {
        return this.publicRepos.find(x => x.id === this.selectedPackage.repo)
      }
      return null
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('closeModal')
    },
    compiledMarkdown: function (input) {
      if (input && input !== null && input !== '') {
        if (typeof input === 'object') {
          return marked(input, { sanitize: true })
        } else {
          return input
        }
      } else {
        return null
      }
    }
  },
  watch: {
    open: function () {
      this.openModal = this.open
    },
    openModal: function () {
      if (!this.openModal) {
        this.closeModal()
      }
    }
  },
  data: () => ({
    openModal: false,
    repositoryTypes: [
      {
        key: 'Nuget',
        value: 0
      },
      {
        key: 'NPM',
        value: 1
      },
      {
        key: 'Maven',
        value: 2
      },
      {
        key: 'Docker',
        value: 3
      },
      {
        key: 'Pypi',
        value: 4
      },
      {
        key: 'Bower',
        value: 5
      },
      {
        key: 'Helm',
        value: 6
      },
      {
        key: 'Raw',
        value: 7
      }
    ]
  })
}
</script>
