<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" id="v-step-0">
          <h1 style="font-size: 36px">Innersource Packages</h1>
          <v-icon color="info">mdi-help-circle</v-icon>
          <a class="pl-1" target="_blank" href="https://confluence.app.medcity.net/c/display/EDKB/Package+Management+on+Nexus+Repository+Management">Need help using Packages</a>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="12" md="6" id="v-step-1">
          <v-text-field
            label="Search for packages by name, description, or team"
            prepend-inner-icon="mdi-magnify"
            placeholder=""
            solo
            clearable
            v-model="search"
            :loading="isPackageLoading"
          ></v-text-field>
        </v-col>
    </v-row>
    <v-divider/>
    <v-row class="mt-3" v-if="isPackageLoading && packages.length == 0">
      <package-skeleton/>
    </v-row>
    <v-row>
      <v-col>
        <span v-if="packages.length === 0 && !isPackageLoading">No Packages found</span>
        <v-row>
          <v-col cols="12" xl="4" lg="6" v-for="(item) in packageDictionary" :key="item.id">
            <v-card
              min-height="130"
              hover
              ripple
              class="mx-auto"
              @click="openPackageDialog(item)"
              :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            >
              <v-list-item>
                <package-avatar :type="item.type" />
                <v-list-item-content>
                  <v-list-item-title class="headline">{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>by {{ item.teamName }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.tags">
                    <v-chip
                      v-for="(tag, tagIndex) in item.tags"
                      :key="tagIndex"
                      class="ma-1 pa-3"
                    >
                      {{tag}}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="packageCount > 1">
          <v-col>
            <v-pagination
              v-model="packagePage"
              :length="packageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <package-dialog :open="packageDialogOpen" :public-repos="repositories" :selectedPackage="selectedPackage" @closeModal="packageDialogOpen = false"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import packageSkeleton from './components/packageSkeleton.vue'
import packageAvatar from './components/packageAvatar.vue'
import packageDialog from './components/packageDialog.vue'

export default {
  name: 'PackageList',
  components: {
    'package-skeleton': packageSkeleton,
    'package-avatar': packageAvatar,
    'package-dialog': packageDialog
  },
  computed: {
    packageDictionary: function () {
      if (this.packages) {
        let dict = {}
        this.packages.forEach(pkg => {
          if (dict[pkg.name] && dict[pkg.name].type === pkg.artifactType) {
            dict[pkg.name].versions.push(pkg)
          } else {
            dict[pkg.name] = {
              versions: [
                pkg
              ],
              type: pkg.artifactType,
              name: pkg.name,
              team: pkg.teamId,
              teamName: pkg.teamName,
              repo: pkg.publicRepositoryId,
              tags: pkg.tags,
              description: pkg.tags
            }
          }
        })
        return dict
      }
      return { }
    }
  },
  watch: {
    packagePage: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getPublicPackages()
      }
    },
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.packagePage = 1
        this.getPublicPackages()
      }, 500)
    }
  },
  data: () => ({
    isPackageLoading: true,
    searchTimeout: null,
    packagePage: 1,
    packageLimit: 12,
    packageCount: 0,
    packages: [ ],
    search: '',
    selectedRepositoryType: null,
    selectedPackage: null,
    repositories: [ ],
    packageDialogOpen: false
  }),
  methods: {
    getPublicPackages: function () {
      this.isPackageLoading = true
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories/packages?page=${this.packagePage}&limit=${this.packageLimit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isPackageLoading = false
            this.packages = response.data.items
            this.packageCount = Math.ceil(response.data.totalCount / this.packageLimit)
          },
          (error) => {
            this.isPackageLoading = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    },
    openPackageDialog: function (item) {
      this.selectedPackage = item
      this.packageDialogOpen = true
    },
    getPublicRepositories: function () {
      return axios(
        `${process.env.VUE_APP_REPO_BASEURL}/repositories`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.repositories = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getEnvironments() })
          })
    }
  },
  mounted: function () {
    this.$store.commit('breadcrumbs', [
      {
        text: 'Packages',
        exact: true,
        disabled: false,
        to: '/packages'
      }
    ])
    this.getPublicRepositories()
    this.getPublicPackages()
  }
}
</script>

<style>
.api-name {
  font-size: 20px;
}

.product-name {
  font-size: 20px;
}
</style>
